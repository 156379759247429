<template lang='pug'>
WaitForExtension2(
  :extensionId='extensionId'
)
  template(
    v-slot:ready
  )
    v-container
      v-card(
        flat
      )
        v-card-title
          | Food Tracker Apps
        v-card-text
          v-list(
            color='transparent'
          )
            v-list-item-group
              v-list-item(
                href='https://shareasale.com/r.cfm?b=766203&u=3479135&m=61121&urllink=&afftrack='
              )
                v-list-item-icon
                  v-icon mdi-link
                v-list-item-content
                  v-list-item-title(
                    v-text='"Cronometer"'
                  )
              v-list-item(
                href='https://www.eatandtrack.com/'
              )
                v-list-item-icon
                  v-icon mdi-link
                v-list-item-content
                  v-list-item-title(
                    v-text='"EatAndTrack"'
                  )
              v-list-item(
                href='https://lifesum.com/'
              )
                v-list-item-icon
                  v-icon mdi-link
                v-list-item-content
                  v-list-item-title(
                    v-text='"Lifesum"'
                  )
              v-list-item(
                href='https://www.loseit.com/'
              )
                v-list-item-icon
                  v-icon mdi-link
                v-list-item-content
                  v-list-item-title(
                    v-text='"Lose It!"'
                  )
              v-list-item(
                href='https://www.myfitnesspal.com/'
              )
                v-list-item-icon
                  v-icon mdi-link
                v-list-item-content
                  v-list-item-title(
                    v-text='"MyFitnessPal"'
                  )
              v-list-item(
                href='https://www.macrosfirst.com/'
              )
                v-list-item-icon
                  v-icon mdi-link
                v-list-item-content
                  v-list-item-title(
                    v-text='"MacrosFirst"'
                  )
              v-list-item(
                href='https://www.mynetdiary.com/'
              )
                v-list-item-icon
                  v-icon mdi-link
                v-list-item-content
                  v-list-item-title(
                    v-text='"MyNetDiary"'
                  )
  template(
    v-slot:default
  )
</template>

<script>
import WaitForExtension2 from './WaitForExtension2.vue';

export default
{
  components:
  {
    WaitForExtension2,
  },
  data()
  {
    return {
      extensionId: 'mfdcclpjngnnnhplbbldfnbfkjipfald',
    };
  },
}
</script>