<template lang='pug'>
div
  slot(
    :settings='settings'
    :settingsDefault='settingsDefault'
  )
</template>

<script>
import { assign } from 'lodash/fp';

const settingsDefault =
{
  net_carbs: true,
  net_calories: true,
  exporting_period: 365,
  exporting_period_calories: 30,
  carbs: 0,
  fat: 0,
  protein: 0,
  date_format: 'shortDate',
  daily_goals:
  [
    { carbs: 0, fat: 0, protein: 0 },
    { carbs: 0, fat: 0, protein: 0 },
    { carbs: 0, fat: 0, protein: 0 },
    { carbs: 0, fat: 0, protein: 0 },
    { carbs: 0, fat: 0, protein: 0 },
    { carbs: 0, fat: 0, protein: 0 },
    { carbs: 0, fat: 0, protein: 0 },
  ],
  presets:
  [
    { carbs: 0, fat: 0, protein: 0 },
  ],
};

const settings = function ()
{
  return assign
  (this.settingsDefault)
  (this.settingsInitial);
};

const mounted = function ()
{
  this.$emit('update:settings', this.settings);
  this.$emit('update:settingsDefault', this.settingsDefault);
};

export default
{
  computed:
  {
    settings,
  },
  data()
  {
    return {
      settingsDefault,
    };
  },
  mounted,
  props:
  {
    settingsInitial: { required: false },
  },
};
</script>