<template lang='pug'>
v-row
  v-col
    v-list
      v-list-group(
        v-for='(report, index) in reports'
        :key='index'
        @click='download(report)'
      )
        template(
          v-slot:activator
        )
          v-list-item-content
            v-list-item-title {{ listItemTitles[index] }}
        v-list-item(
          :href='href'
          :download='filename'
        )
          v-list-item-icon
            v-icon mdi-download
          v-list-item-content
            v-list-item-title Download chart data
    //- v-container(
    //-   v-if='reports.length'
    //-   fluid
    //- )
    //-   v-btn(
    //-     :href='hrefCombined'
    //-     :download='filenameCombined'
    //-     depressed
    //-   )
    //-     | #[v-icon(left) mdi-download] Download combined chart data
  v-col
    LineChart(
      v-if='report'
      :key='reportKey'
      :title='report.title'
      :x='reportToDates(report)'
      :y='reportToTotals(report)'
    )
</template>

<script>
import { at, get, head, join, last, map, mergeAll, over, pipe, placeholder, range, reverse, size, split, subtract, wrap, zip, zipAll } from 'lodash/fp';
import moment from 'moment';
import Papa from 'papaparse';

import LineChart from './LineChart.vue';

const reportToExportMonth = pipe
([
  get('results'),
  reverse,
  get('0.date'),
  split('/'),
  map(window.Number),
  head,
  subtract(placeholder, 1),
]);

const reportToExportDateOfMonth = pipe
([
  get('results'),
  reverse,
  get('0.date'),
  split('/'),
  map(window.Number),
  last,
]);

const reportToExportDate = report =>
(
  moment()
  .month(reportToExportMonth(report))
  .date(reportToExportDateOfMonth(report))
  .startOf('day')
);

const sanitizeReport = report =>
{
  return map
  (([date, total]) => ({ date, total }))
  (
    zip
    (reportToDates(report))
    (reportToTotals(report))
  );
};

const download = function (report)
{
  this.report = report;
};

const reportToDates = report =>
{
  const exportDateToDate = exportDate => n =>
  (
    exportDate
    .clone()
    .subtract(n, 'days')
    .format('YYYY-MM-DD')
  );

  const exportDate = reportToExportDate(report);

  return pipe
  ([
    get('results'),
    size,
    range(0),
    map(exportDateToDate(exportDate)),
  ])
  (report);
};

const reportToTotals = report =>
{
  return pipe
  ([
    get('results'),
    reverse,
    map('total'),
  ])
  (report);
};

const csv = function ()
{
  return pipe
  ([
    get('report'),
    sanitizeReport,
    Papa.unparse,
  ])
  (this);
};

const filename = function ()
{
  return pipe
  ([
    get('report.title'),
    result => `${ result }.csv`,
  ])
  (this);
};

const filenameCombined = function ()
{
  return pipe
  ([
    wrap(null)('Combined'),
    result => `${ result }.csv`,
  ])
  (this);
};

const csvToObjectUrl = pipe
([
  result => new window.Blob([result]),
  result => window.URL.createObjectURL(result, { type: 'text/plain' }),
]);

const href = function ()
{
  return pipe
  ([
    get('csv'),
    csvToObjectUrl,
  ])
  (this);
};

const hrefCombined = function ()
{
  return pipe
  ([
    get('csvCombined'),
    csvToObjectUrl,
  ])
  (this);
};

const report = null;

const reports = { required: true };

const csvCombined = function ()
{
  return pipe
  ([
    get('reports'),
    map
    (
      report =>
      (
        pipe
        ([
          sanitizeReport,
          map(({ date, total }) => ({ date, [report.label]: total })),
        ])
        (report)
      )
    ),
    zipAll,
    map(mergeAll),
    Papa.unparse,
  ])
  (this);
};

const listItemTitles = function ()
{
  return pipe
  ([
    get('reports'),
    map
    (
      pipe
      ([
        over
        ([
          get('title'),
          pipe([get('results'), last, get('date')]),
          pipe([get('results'), size]),
        ]),
        join(' - '),
      ])
    ),
  ])
  (this);
};

const reportKey = function ()
{
  return pipe
  ([
    at(['title', 'results.0.date']),
    window.JSON.stringify,
  ])
  (this?.report);
};

export default
{
  components:
  {
    LineChart,
  },
  computed:
  {
    csv,
    csvCombined,
    filename,
    filenameCombined,
    href,
    hrefCombined,
    listItemTitles,
    reportKey,
  },
  data()
  {
    return {
      report
    };
  },
  methods:
  {
    download,
    reportToDates,
    reportToTotals,
  },
  props:
  {
    reports
  },
}
</script>