<template lang='pug'>
v-card(
  flat
  outlined
  color='grey lighten-4'
)
  v-card-title
    | Set your macros
  v-card-subtitle
    | Convert grams and calories
  WaitForExtension2(
    :extensionId='extensionId'
  )
    template(
      v-slot:ready='{ response }'
    )
      SettingsRead2(
        :settingsInitial='response.settings'
        @update:settings='settings => $store.commit("settings_update", settings)'
      )
      v-container(
        v-for='(preset, index) in presets'
        :key='index'
      )
        v-card(
          outlined
        )
          v-container(
            fluid
          )
            v-row
              v-col
                v-menu(
                  offset-y
                )
                  template(
                    v-slot:activator='{ attrs, on }'
                  )
                    v-btn(
                      v-if='!index'
                      v-bind='attrs'
                      v-on='on'
                      outlined
                      disabled
                    )
                      | Default Preset
                    v-btn(
                      v-if='index'
                      v-bind='attrs'
                      v-on='on'
                      outlined
                    )
                      | Preset {{ index + 1 }} #[v-icon(right) mdi-menu-down]
                  v-list
                    v-list-item(
                      @click='preset_delete(index)'
                    )
                      v-list-item-title
                        | Delete preset
            EventToProp(
              v-slot='{ prop: calories_total, updateProp: calories_total_update, timestamp }'
            )
              v-row
                v-col(
                  cols='12'
                  sm='6'
                  md='8'
                  lg='8'
                  xl='8'
                )
                  v-text-field(
                    label='Preset Name (optional)'
                    type='text'
                    :value='preset?.name'
                    @input='value => preset_update_name(`${ index }.name`)(value)'
                    outlined
                  )
                  MacroCalculatorFormCalories3(
                    :initial_carbs='preset.carbs'
                    :initial_fat='preset.fat'
                    :initial_protein='preset.protein'
                    @update:carbs='value => preset_update(`${ index }.carbs`)(value)'
                    @update:fat='value => preset_update(`${ index }.fat`)(value)'
                    @update:protein='value => preset_update(`${ index }.protein`)(value)'
                    @update:calories_total='calories_total_update'
                  )
                  ButtonHover(
                    @click='preset_copy_legacy(index)'
                    icon='mdi-check'
                  )
                    | Save to MFP
                v-col(
                  cols='12'
                  sm='6'
                  md='4'
                  lg='4'
                  xl='4'
                )
                  PieChart2(
                    :initial_carbs='preset.carbs'
                    :initial_fat='preset.fat'
                    :initial_protein='preset.protein'
                    :isDark='false'
                  )
              //- v-expansion-panels
              //-   v-expansion-panel
              //-     v-expansion-panel-header
              //-       | Save to MFP
              //-     v-expansion-panel-content
              //-       p Note: You may have to update your calories on the following page.
              //-       ButtonHover(
              //-         @click='preset_copy_legacy(index)'
              //-         icon='mdi-check'
              //-       )
              //-         | Okay
      v-container
        v-row
          v-col
            ButtonHover(
              @click='preset_add'
              icon='mdi-plus'
            )
              | Add preset
      v-container
        v-row
          v-col
            v-alert(
              icon='mdi-information'
              outlined
            )
              | Extension not working? Try disabling ad blockers.
    template(
      v-slot:default
    )
      v-container
        v-row
          v-col
            .d-flex.justify-center
              v-btn(
                href='https://chrome.google.com/webstore/detail/mfdcclpjngnnnhplbbldfnbfkjipfald'
                color='primary'
                depressed
              )
                | #[v-icon(left) mdi-google-chrome] Get this extension
</template>

<script>
import { cond, defaultTo, eq, get, identity, pipe, toFinite } from 'lodash/fp';

import EventToProp from '@thomasphan/vue-components/src/components/EventToProp.vue';
import MacroCalculatorFormCalories3 from '@thomasphan/vue-components/src/components/MacroCalculatorFormCalories3.vue';
import PieChart2 from '@thomasphan/vue-components/src/components/PieChart2.vue';

import SettingsRead2 from './SettingsRead2.vue';
import WaitForExtension2 from './WaitForExtension2.vue';
import ButtonHover from './ButtonHover.vue';

const presets = function ()
{
  return pipe
  ([
    get('$store.state.settings.settings.presets'),
    defaultTo([]),
  ])
  (this);
};

const preset_update = function (path)
{
  return value =>
  {
    cond
    ([
      [
        eq(0),
        pipe
        ([
          toFinite,
          value => this.$store.commit('preset_update', { path, value }),
        ]),
      ],
      [
        identity,
        pipe
        ([
          toFinite,
          value => this.$store.commit('preset_update', { path, value }),
        ]),
      ],
    ])
    (value);

    window
    ?.chrome
    ?.runtime
    ?.sendMessage
    ?.
    (
      this.extensionId,
      this.$store.state.settings,
      () =>
      {
        cond
        ([[
          identity,
          window.console.log,
        ]])
        (
          window
          ?.chrome
          ?.runtime
          ?.lastError
        );
      },
    );
  };
};

const preset_update_name = function (path)
{
  return value =>
  {
    this.$store.commit('preset_update', { path, value });

    window
    ?.chrome
    ?.runtime
    ?.sendMessage
    ?.
    (
      this.extensionId,
      this.$store.state.settings,
      () =>
      {
        cond
        ([[
          identity,
          window.console.log,
        ]])
        (
          window
          ?.chrome
          ?.runtime
          ?.lastError
        );
      },
    );
  };
};

const preset_add = function ()
{
  this.$store.commit('preset_add');

  window
  ?.chrome
  ?.runtime
  ?.sendMessage
  ?.
  (
    this.extensionId,
    this.$store.state.settings,
    () =>
    {
      cond
      ([[
        identity,
        window.console.log,
      ]])
      (
        window
        ?.chrome
        ?.runtime
        ?.lastError
      );
    },
  );
};

const preset_delete = function (index)
{
  this.$store.commit('preset_delete', index);

  window
  ?.chrome
  ?.runtime
  ?.sendMessage
  ?.
  (
    this.extensionId,
    this.$store.state.settings,
    () =>
    {
      cond
      ([[
        identity,
        window.console.log,
      ]])
      (
        window
        ?.chrome
        ?.runtime
        ?.lastError
      );
    },
  );
};

const preset_copy_legacy = function (index)
{
  // const mutation = 'preset_copy_legacy';
  const mutation = 'preset_copy';

  const params =
  {
    event_callback: () =>
    {
      this.$store.commit(mutation, index);

      window
      ?.chrome
      ?.runtime
      ?.sendMessage
      ?.
      (
        this.extensionId,
        this.$store.state.settings,
        () =>
        {
          cond
          ([[
            identity,
            window.console.log,
          ]])
          (
            window
            ?.chrome
            ?.runtime
            ?.lastError
          );
        },
      );

      window
      ?.chrome
      ?.runtime
      ?.sendMessage
      ?.
      (
        this.extensionId,
        'save_goals_to_myfitnesspal',
        response =>
        {
          cond
          ([[
            identity,
            // () => window.location = 'https://www.myfitnesspal.com/account/my-goals/fitness',
            () => window.location = 'https://www.myfitnesspal.com/account/my-goals/daily-nutrition-goals',
          ]])
          (
            response
            ?.save_goals_to_myfitnesspal
          );

          cond
          ([[
            identity,
            window.console.log,
          ]])
          (
            window
            ?.chrome
            ?.runtime
            ?.lastError
          );
        },
      );
    }
  };

  window.gtag('event', mutation, params);
};

export default
{
  components:
  {
    ButtonHover,
    EventToProp,
    MacroCalculatorFormCalories3,
    PieChart2,
    SettingsRead2,
    WaitForExtension2,
  },
  computed:
  {
    presets,
  },
  data()
  {
    return {
      extensionId: 'mfdcclpjngnnnhplbbldfnbfkjipfald'
      // extensionId: 'pjbpeigmakpjfccjbhleodjkhpfokogk'
    };
  },
  methods:
  {
    preset_add,
    preset_copy_legacy,
    preset_delete,
    preset_update,
    preset_update_name,
  },
}
</script>