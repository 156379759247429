<template lang='pug'>
div
  v-container(
    fluid
  )
    MacroSettings2
  WaitForExtension2(
    extensionId='mfdcclpjngnnnhplbbldfnbfkjipfald'
  )
    template(
      v-slot:ready
    )
      v-container(
        fluid
      )
        v-card(
          flat
          outlined
        )
          v-card-title
            | How I would hit 175g of protein
          v-card-subtitle
            | EatAndTrack.com Demo
          v-card-text
            v-container.pa-0
              v-responsive(
                :aspect-ratio='16/9'
              )
                iframe(
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/K8IEQEQ_Ei0?si=s3vQDE9rU512nwAb"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                )
  v-container(
    fluid
  )
    InteractiveCharts2
  v-container(
    fluid
  )
    Recipes
  Apps
</template>

<script>
import Apps from './Apps.vue';
import InteractiveCharts from './InteractiveCharts.vue';
import InteractiveCharts2 from './InteractiveCharts2.vue';
import MacroSettings2 from './MacroSettings2.vue';
import Recipes from './Recipes.vue';
import WaitForExtension2 from './WaitForExtension2.vue';

import WaitForExtension from '@thomasphan/vue-components/src/components/WaitForExtension.vue';

export default
{
  components:
  {
    Apps,
    InteractiveCharts,
    InteractiveCharts2,
    MacroSettings2,
    Recipes,
    WaitForExtension,
    WaitForExtension2,
  }
};
</script>