<template lang='pug'>
Home
</template>

<script>
import Home from '@/components/Home.vue';

export default
{
  components:
  {
    Home
  }
}
</script>