<template lang='pug'>
v-hover(
  v-slot='{ hover }'
)
  v-btn(
    @click='$emit("click")'
    outlined
  )
    v-icon(
      left
    )
      template(
        v-if='hover'
      )
        | {{ icon }}
      template(
        v-else
      )
        | {{ icon }}-outline
    slot
      | Okay
</template>

<script>
export default
{
  props:
  {
    icon:
    {
      default: 'mdi-check',
      required: false,
    }
  }
}
</script>