<style scoped>
.example {
  background-image: linear-gradient(138.83deg, rgb(252, 219, 97) 5.51%, rgb(251, 156, 101) 97.21%);
  border-radius: 0.8rem;
}
.v-card-border {
  outline-color: rgba(255, 255, 255, 0.5);
  outline-style: solid;
  outline-width: 0.8rem;
  border-radius: 0.8rem;
  background-color: rgb(0, 0, 0);
}
</style>

<template lang='pug'>
v-card.example.pa-12(
  flat
)
  v-responsive.v-card-border.pa-6(
    :aspect-ratio='1'
  )
    canvas(
      ref='plot'
      style='height:100%;width:100%'
    )
</template>

<script>
import { reverse } from 'lodash/fp';

const mounted = async function ()
{
  const backgroundColor = 'rgb(250, 155, 101)';

  const borderColor = backgroundColor;

  const datasets =
  [{
    label: this.title,
    data: this.ySanitized,
    backgroundColor,
    borderColor,
  }];

  const labels = this.xSanitized;

  const data =
  {
    labels,
    datasets,
  };

  const config =
  {
    type: 'line',
    data,
    options:
    {
      scales:
      {
        x:
        {
          border:
          {
            display: false
          },
          grid:
          {
            display: false
          },
          ticks:
          {
            color: 'rgb(255, 255, 255)',
            padding: 12,
          },
          title:
          {
            color: 'rgb(255, 255, 255)',
            display: true,
            text: 'Date',
          },
        },
        y:
        {
          border:
          {
            display: false,
            dash: [2, 2]
          },
          grid:
          {
            drawTicks: false,
            color: 'rgba(255, 255, 255, 0.4)',
          },
          ticks:
          {
            color: 'rgb(255, 255, 255)',
            padding: 12,
          },
          title:
          {
            color: 'rgb(255, 255, 255)',
            display: true,
            text: 'Value',
          },
          position: 'right',
        },
      },
      plugins:
      {
        legend:
        {
          align: 'start',
          labels:
          {
            color: 'rgb(255, 255, 255)',
            boxWidth: 8,
            boxHeight: 8,
          }
        }
      }
    },
  };

  new window.Chart
  (
    this.$refs.plot,
    config,
  );
};

const xSanitized = function ()
{
  return reverse(this.x);
};

const ySanitized = function ()
{
  return reverse(this.y);
};

export default
{
  computed:
  {
    xSanitized,
    ySanitized,
  },
  mounted,
  props:
  {
    title: { required: true },
    x: { required: true },
    y: { required: true },
  },
}
</script>