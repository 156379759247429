import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Recipes from '../views/Recipes.vue'

Vue.use(VueRouter)

const routes =
[
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/myfitnesspal-x-foodfastfit',
    name: 'myfitnesspal-x-foodfastfit',
    component: Home,
  },
  {
    path: '/recipes',
    name: 'recipes',
    component: Recipes,
  },
]

const router = new VueRouter
({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router