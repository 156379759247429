<template lang='pug'>
div
  slot(
    v-if='!response'
  )
  slot(
    v-if='response'
    name='ready'
    :response='response'
  )
</template>

<script>
import { cond, identity } from 'lodash/fp';

const mounted = function ()
{
  window
  ?.chrome
  ?.runtime
  ?.sendMessage
  ?.
  (
    this.extensionId,
    null,
    response =>
    {
      this.response = response;

      this.$emit('update:response', this.response);

      cond
      ([[
        identity,
        window.console.log,
      ]])
      (
        window
        ?.chrome
        ?.runtime
        ?.lastError
      );
    },
  );
};

export default
{
  data()
  {
    return {
      response: null,
    };
  },
  mounted,
  props:
  {
    extensionId: { required: true },
  },
}
</script>