<template lang='pug'>
v-card(
  flat
  outlined
)
  v-card-title Interactive Charts
  v-card-subtitle Visualize your MFP data
  WaitForExtension2(
    :extensionId='extensionId'
  )
    template(
      v-slot:default
    )
      v-container
        v-row
          v-col
            .d-flex.justify-center
              v-btn(
                href='https://chrome.google.com/webstore/detail/amkolaphnpboccgiidldgjfihahlhlkb'
                color='primary'
                depressed
              )
                | #[v-icon(left) mdi-google-chrome] Get this extension
    template(
      v-slot:ready='{ response }'
    )
      v-container
        v-stepper.elevation-0(
          non-linear
          vertical
          :value='response?.reportsV2 ? 2 : 1'
        )
          v-stepper-step(
            editable
            step='1'
          )
            | View your reports on MyFitnessPal
          v-stepper-content(
            step='1'
          )
            .pa-2
              //- v-text-field(
              //-   :label='`Export report data for the last ${ exporting_period } days`'
              //-   :value='exporting_period'
              //-   required
              //-   type='number'
              //-   @input='exporting_period_update_interactive_charts'
              //-   outlined
              //- )
              v-hover(
                v-slot='{ hover }'
              )
                v-btn(
                  @click='redirect'
                  outlined
                )
                  v-icon(
                    left
                  )
                    template(
                      v-if='hover'
                    )
                      | mdi-chart-box
                    template(
                      v-else
                    )
                      | mdi-chart-box-outline
                  | Reports
          v-stepper-step(
            editable
            step='2'
          )
            //- | View your data
            | View your reports on https://myfitnesspal.foodfastfit.com/
          v-stepper-content(
            step='2'
          )
            Reports(
              v-if='response.reportsV2'
              :reports='response.reportsV2'
            )
</template>

<script>
import { cond, defaultTo, identity, pipe } from 'lodash/fp';

import Reports from './Reports.vue';
import WaitForExtension2 from './WaitForExtension2.vue';

const exporting_period = function ()
{
  return pipe
  ([
    defaultTo(365),
  ])
  (
    this
    ?.$store
    ?.state
    ?.settings
    ?.settings_interactive_charts
    ?.exporting_period
  );
};

const mounted = function ()
{
  this.exporting_period_update_interactive_charts(this.exporting_period);
};

const exporting_period_update_interactive_charts = function (exporting_period)
{
  cond
  ([[
    identity,
    pipe
    ([
      window.Number,
      exporting_period => this.$store.commit('exporting_period_update_interactive_charts', exporting_period),
    ]),
  ]])
  (exporting_period);

  const settings = this
  ?.$store
  ?.state
  ?.settings
  ?.settings_interactive_charts;

  window
  ?.chrome
  ?.runtime
  ?.sendMessage
  ?.
  (
    this.extensionId,
    { settings },
    () =>
    {
      cond
      ([[
        identity,
        window.console.log,
      ]]),
      (
        window
        ?.chrome
        ?.runtime
        ?.lastError
      );
    },
  );
};

const redirect = function ()
{
  window
  ?.chrome
  ?.runtime
  ?.sendMessage
  ?.
  (
    this.extensionId,
    'redirect',
    () =>
    {
      cond
      ([[
        identity,
        window.console.log,
      ]]),
      (
        window
        ?.chrome
        ?.runtime
        ?.lastError
      );

      window.location = 'https://www.myfitnesspal.com/reports';
    },
  );
};

export default
{
  components:
  {
    Reports,
    WaitForExtension2,
  },
  computed:
  {
    exporting_period
  },
  data()
  {
    return {
      extensionId: 'amkolaphnpboccgiidldgjfihahlhlkb'
    };
  },
  methods:
  {
    exporting_period_update_interactive_charts,
    redirect,
  },
  mounted,
}
</script>