<template lang='pug'>
v-card(
  flat
  outlined
)
  v-card-title
    | Recipes
  v-card-subtitle
    | Search your MFP recipes
  v-container
    WaitForExtension2(
      :extensionId='extensionId'
      @update:response='setNames'
    )
      template(
        v-slot:default
      )
        v-row
          v-col
            .d-flex.justify-center
              v-btn(
                href='https://chrome.google.com/webstore/detail/mfp-recipes-by-foodfastfi/fpcgaacncglfohpfjcfidijnjnlmlhdd'
                color='primary'
                depressed
              )
                | #[v-icon(left) mdi-google-chrome] Get this extension
      template(
        v-slot:ready='{ response }'
      )
        v-container
          v-stepper.elevation-0(
            non-linear
            vertical
            :value='names?.length ? 2 : 1'
          )
            v-stepper-step(
              step='1'
              editable
            )
              | Sync recipes
            v-stepper-content(
              step='1'
            )
              .pa-2
                ButtonHover(
                  @click='sync'
                )
                  | Sync
            v-stepper-step(
              step='2'
              editable
            )
              | Search recipes
            v-stepper-content(
              step='2'
            )
              .pa-2
                v-text-field(
                  label='Search your recipes by name'
                  v-model='search'
                  required
                  type='text'
                  @input=''
                  outlined
                )
                v-list
                  v-list-item(
                    v-for='name in namesFiltered'
                    :key='name[1]'
                    :href='name[1]'
                  )
                    v-list-item-content
                      v-list-item-title
                        | {{ name[0] }}
</template>

<script>
import { filter, get, nth, over, pipe } from 'lodash/fp';

import ReportsRead from '@thomasphan/vue-components/src/components/ReportsRead.vue';
import SettingsRead from '@thomasphan/vue-components/src/components/SettingsRead.vue';

import ButtonHover from './ButtonHover.vue';
import Reports from './Reports.vue';
import WaitForExtension2 from './WaitForExtension2.vue';

const namesFiltered = function ()
{
  return pipe
  ([
    over
    ([
      pipe
      ([
        get('names'),
      ]),
      pipe
      ([
        get('search'),
      ]),
    ]),
    ([names, search]) =>
    (
      pipe
      ([
        filter
        (
          pipe
          ([
            nth(0),
            name => name.toLowerCase().includes(search.toLowerCase()),
          ]),
        ),
      ])
      (names)
    ),
  ])
  (this);
};

const setNames = function (response)
{
  this.names = pipe
  ([
    get('names'),
  ])
  (response);
};

const sync = function ()
{
  try
  {
    window
    .chrome
    .runtime
    .sendMessage
    (
      this.extensionId,
      { shouldSync: true },
    );
  }
  catch (err)
  {
    window.console.log({ err });
  }
};

export default
{
  components:
  {
    ButtonHover,
    Reports,
    ReportsRead,
    SettingsRead,
    WaitForExtension2,
  },
  computed:
  {
    namesFiltered,
  },
  data()
  {
    return {
      extensionId: 'fpcgaacncglfohpfjcfidijnjnlmlhdd',
      names: null,
      search: '',
    };
  },
  methods:
  {
    setNames,
    sync,
  },
}
</script>