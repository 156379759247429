<template lang='pug'>
v-app
  v-app-bar(
    app
    dark
    flat
  )
    v-btn(
      text
      href='https://foodfastfit.com/'
    )
      | #[v-icon(left) mdi-pound] FOODFASTFIT
    WaitForExtension2(
      extensionId='mfdcclpjngnnnhplbbldfnbfkjipfald'
    )
      template(
        v-slot:ready
      )
        v-hover(
          v-slot='{ hover }'
        )
          v-btn.d-none.d-sm-flex(
            href='https://eatandtrack.com/'
            outlined
            color='orange'
          )
            v-icon(
              left
            )
              template(
                v-if='hover'
              )
                | mdi-alert-decagram
              template(
                v-else
              )
                | mdi-alert-decagram-outline
            | New: eatandtrack.com
      template(
        v-slot:default
      )
        v-btn.d-none.d-sm-flex(
          text
          href='https://chrome.google.com/webstore/detail/mfp-recipes-by-foodfastfi/fpcgaacncglfohpfjcfidijnjnlmlhdd'
        )
          | #[v-icon(left) mdi-google-chrome] New: Quickly search your MFP recipes
        //- v-hover(
        //-   v-slot='{ hover }'
        //- )
        //-   v-btn.d-none.d-sm-flex(
        //-     href='https://eatandtrack.com/'
        //-     outlined
        //-     color='orange'
        //-   )
        //-     v-icon(
        //-       left
        //-     )
        //-       template(
        //-         v-if='hover'
        //-       )
        //-         | mdi-alert-decagram
        //-       template(
        //-         v-else
        //-       )
        //-         | mdi-alert-decagram-outline
        //-     | New: eatandtrack.com
    v-spacer
  v-main
    Uid
      router-view
    v-container
      Footer
</template>

<script>
import Footer from '@thomasphan/vue-components/src/components/Footer.vue';
import Uid from '@thomasphan/vue-components/src/components/Uid.vue';

import WaitForExtension2 from './components/WaitForExtension2.vue';

export default
{
  components:
  {
    Footer,
    Uid,
    WaitForExtension2,
  }
};
</script>