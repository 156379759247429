<template lang='pug'>
v-card(
  flat
  outlined
)
  v-card-title Interactive Charts
  v-card-subtitle Visualize your MFP data
  SettingsRead(
    v-if='installed'
    @update:settings='settings => $store.commit("settings_update", settings)'
  )
  v-container(
    v-if='!installed'
  )
    v-row
      v-col
        .d-flex.justify-center
          v-btn(
            href='https://chrome.google.com/webstore/detail/amkolaphnpboccgiidldgjfihahlhlkb'
            color='primary'
            depressed
          )
            | #[v-icon(left) mdi-google-chrome] Get this extension
  v-container(
    v-if='installed'
  )
    v-stepper.elevation-0(
      non-linear
      vertical
    )
      v-stepper-step(
        editable
        step='1'
      ) Visit your reports page
      v-stepper-content(
        step='1'
      )
        .pa-2
          v-text-field(
            :label='`Export report data for the last ${ exporting_period } days`'
            :value='exporting_period'
            required
            type='number'
            @input='exporting_period_update'
            outlined
          )
          v-btn(
            href='https://www.myfitnesspal.com/reports'
            outlined
          )
            | Reports
      v-stepper-step(
        step='2'
      ) Choose "Interactive Charts by FOODFASTFIT"
      v-stepper-content(
        step='2'
      )
      v-stepper-step(
        editable
        step='3'
      ) View your data
      v-stepper-content(
        step='3'
      )
        ReportsRead(
          v-slot='{ reports }'
        )
          Reports(
            :reports='reports'
          )
</template>

<script>
import fp from 'lodash/fp';

import Reports from '@/components/Reports.vue';

import ReportsRead from '@thomasphan/vue-components/src/components/ReportsRead.vue';
import SettingsRead from '@thomasphan/vue-components/src/components/SettingsRead.vue';

const exporting_period = function ()
{
  return fp.pipe
  ([
    fp.get('$store.state.settings.settings.exporting_period'),
    fp.defaultTo(365),
  ])
  (this);
};

const exporting_period_update = function (exporting_period)
{
  fp.cond
  ([[
    fp.identity,
    fp.pipe
    ([
      window.Number,
      exporting_period => this.$store.commit('exporting_period_update', exporting_period),
    ]),
  ]])
  (exporting_period);
};

export default
{
  components:
  {
    Reports,
    ReportsRead,
    SettingsRead,
  },
  computed:
  {
    exporting_period
  },
  methods:
  {
    exporting_period_update
  },
  props:
  {
    installed: { required: true }
  },
}
</script>