<template lang='pug'>
div
  v-container(
    fluid
  )
    Recipes
  Apps
</template>

<script>
import Apps from '../components/Apps.vue';
import Recipes from '../components/Recipes.vue';

export default
{
  components:
  {
    Apps,
    Recipes,
  }
};
</script>