import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { colors } from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify
({
  theme:
  {
    themes:
    {
      dark:
      {
        primary: colors.green.darken2,
        warning: colors.lightGreen,
      },
      light:
      {
        primary: colors.green.darken2,
        warning: colors.lightGreen,
      },
    },
  }
});